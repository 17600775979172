/**
 * Currently this function escapes only '@' character as per this doc:
 * https://vue-i18n.intlify.dev/guide/essentials/syntax#literal-interpolation
 *
 * It is extract for reusability and extensibility in case special characters are added in the future.
 *
 * @param value - string to escape before translation
 *
 * @returns escaped string or undefined if input is undefined
 *
 * @example escapeI18nSpecialCharacters("my@email.com") => "my{'@'}email.com"
 */
export function escapeI18nSpecialCharacters(
  value: string | undefined
): string | undefined {
  if (!value) {
    return value;
  }

  return value.replaceAll("@", "{'@'}");
}
