import { PayoutLocale } from "@payout-link/common/models/payout-locale.model";

/**
 * Searches for a locale string in one or more arrays of PayoutLocale objects.
 * - If the locale string is provided, the function will return the first PayoutLocale::value that contains the locale string.
 * - If the array is empty or does not contain the locale string, the function will return undefined.
 *
 *
 * @param arraysOfLocales - Array of PayoutLocale objects
 * @param locale        - locale string to search for (e.g. "en", "en-US", "de", "de-DE")
 *
 * @returns a locale string if array is non-empty and contains the locale string, otherwise undefined
 */
export function searchLocale(
  arraysOfLocales: PayoutLocale[][],
  locale: string
): string | undefined {
  for (const payoutLocales of arraysOfLocales) {
    const foundLocale = payoutLocales.find((payoutCountryLocale) =>
      payoutCountryLocale.value.includes(locale)
    )?.value;
    if (foundLocale) {
      return foundLocale;
    }
  }
}
