import { toDisplayLabel } from "@payout-link/util/i18n/locale/to-display-label";

export interface Comparable<T> {
  equals: (item: T) => boolean;
}

export interface PayoutLocale extends Comparable<PayoutLocale> {
  id: string;
  value: string;
  label: string;
}

export class PayoutLocaleImpl implements PayoutLocale {
  private readonly localeCode: string;
  private readonly displayLabel: string;

  constructor(localeCode: string) {
    this.localeCode = localeCode;
    this.displayLabel = toDisplayLabel(localeCode);
  }

  get id(): string {
    return this.localeCode;
  }

  get value(): string {
    return this.localeCode;
  }

  get label(): string {
    return this.displayLabel;
  }

  equals(locale: PayoutLocale): boolean {
    return this.id === locale.id;
  }
}
