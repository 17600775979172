<script setup lang="ts">
import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import PayToCardStart from "@payout-link/components/PayToCard/PayToCardStart.vue";
import PayToCardSuccess from "@payout-link/components/PayToCard/PayToCardSuccess.vue";

const { isFulfilled } = usePayoutGlobalStore();
</script>

<template>
  <div class="m-auto flex flex-col items-center gap-4">
    <PayToCardSuccess v-if="isFulfilled" />
    <PayToCardStart v-else />
  </div>
</template>
