<script setup lang="ts">
import Markdown from "markdown-it";
import { onMounted, ref } from "vue";

const props = defineProps<{
  redeemInstructions: string;
}>();

const contentContainer = ref<HTMLElement | null>(null);
onMounted(() => {
  const md = new Markdown();
  const howTo = md.render(props.redeemInstructions);
  if (contentContainer && contentContainer.value) {
    contentContainer.value.innerHTML = howTo;
  }
});
</script>

<template>
  <div>
    <h2 class="mb-6 text-2xl font-semibold">{{ $t("message.how_to_use") }}</h2>
    <div
      ref="contentContainer"
      id="how-to-content"
      data-testid="how-to-section"
      class="how-to-text font-normal"
    />
  </div>
</template>

<style scoped>
:deep() .how-to-text p {
  margin-bottom: 1rem;
}
:deep() .how-to-text p:last-child {
  margin-bottom: 0;
}
</style>
