import * as Sentry from "@sentry/vue";
import { localeToIetfStandard } from "@workspace/packages/payout/src/utils/format";

export type I18nMessages = {
  [locale: string]: {
    error: {
      [key: string]: string;
    };
    message: {
      [key: string]: string;
    };
  };
};

export const SPEND_TRANSLATION = {
  BASE_URL: import.meta.env.VITE_SPEND_TRANSLATION_BASE_URL,
  DEFAULT: import.meta.env.VITE_SPEND_TRANSLATION_DEFAULT,
};

// Safety check to ensure that the language tags are in the BCP 47 IETF standard
export function convertLocalesToIetfStandard(
  messages: I18nMessages
): I18nMessages {
  const updatedMessages: I18nMessages = {};

  Object.keys(messages).forEach((locale) => {
    const updatedLocale = localeToIetfStandard(locale);
    if (updatedLocale) {
      updatedMessages[updatedLocale] = messages[locale];
    }
  });

  return updatedMessages;
}

function logBaseFetchFailure(errorInfo: string | unknown) {
  Sentry.captureException(
    new Error(`Failed to fetch base translations. "${errorInfo}"`)
  );
}

function logProductSpecificFetchFailure(
  productInfo: string,
  errorInfo: string | unknown
) {
  Sentry.captureException(
    new Error(
      `Failed to fetch product specific translations. "${productInfo}" | "${errorInfo}"`
    )
  );
}

export async function tryFetchTranslationMessages(
  productCode: string = "",
  path: string = SPEND_TRANSLATION.DEFAULT
): Promise<I18nMessages> {
  const isBaseTranslation = !productCode;
  const pathPrefix = productCode ? `${productCode}-` : "";

  try {
    const fetchMessages = await fetch(
      `${SPEND_TRANSLATION.BASE_URL}${pathPrefix}${path}`,
      {
        cache: "no-store",
      }
    );

    if (fetchMessages.status !== 200) {
      if (isBaseTranslation) {
        logBaseFetchFailure(`Status code: ${fetchMessages.status}`);
      } else if (fetchMessages.status !== 403) {
        // 403 indicates missing translations for the product which is expected for some products
        logProductSpecificFetchFailure(
          `Product code: ${productCode}`,
          `Status code: ${fetchMessages.status}`
        );
      }
      return {};
    }

    const messages = await fetchMessages.json();
    return convertLocalesToIetfStandard(messages);
  } catch (error) {
    if (isBaseTranslation) {
      logBaseFetchFailure(error);
    } else {
      logProductSpecificFetchFailure(`Product code: ${productCode}`, error);
    }
    return {};
  }
}

export function mergeI18nMessages(
  base: I18nMessages,
  override?: I18nMessages
): I18nMessages {
  // If override is not provided, return base as is
  if (!override) {
    return base;
  }

  const result: I18nMessages = { ...base };

  // Iterate over locales in override
  for (const locale in override) {
    if (override.hasOwnProperty(locale)) {
      if (base[locale]) {
        // Merge 'error' and 'message' fields for locales present in both base and override
        result[locale] = {
          error: {
            ...base[locale].error,
            ...override[locale].error, // override's error messages take precedence
          },
          message: {
            ...base[locale].message,
            ...override[locale].message, // override's regular messages take precedence
          },
        };
      } else {
        // If locale doesn't exist in base, just copy it from override
        result[locale] = override[locale];
      }
    }
  }

  return result;
}
