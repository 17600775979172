export interface ExtraFieldsToDisplay {
  [key: string]: Array<{
    key: string;
    label: string;
    separation?: string;
  }>;
}

interface CustomOnlineCodeLabels {
  [key: string]: string;
}

interface NumericBarcodeLabel {
  [key: string]: string;
}

export const CODE_TOKEN = "{##code##}";

export const customRedeemURLs: { [key: string]: string } = {
  "AMZ-GB": "http://www.amazon.co.uk/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-DE": "http://www.amazon.de/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-ES": "http://www.amazon.es/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-FR": "http://www.amazon.fr/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-IT": "http://www.amazon.it/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-NL": "http://www.amazon.nl/gc/redeem?claimCode=" + CODE_TOKEN,
  "AMZ-US": "http://www.amazon.com/gc/redeem?claimCode=" + CODE_TOKEN,
  "PLAY-GB": "https://play.google.com/redeem?code=" + CODE_TOKEN,
};

export const productsToDisplayNumericBarcode: string[] = ["GEMO-FR"];

export const productsToHideCardNumber: string[] = [
  "IMVU-CA",
  "RESTO-AT",
  "RESTO-DE",
  "GRPCR-MX",
  "EBAY-US",
  "LBLNG-DE",
  "LBLNG-AT",
];
