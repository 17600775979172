import { defineStore } from "pinia";
import {
  RedemptionData,
  RedemptionResponse,
} from "@payout-link/api.generated/scion";
import { getSpendDetails } from "@payout-link/services/redemption";

export const usePayoutGlobalStore = defineStore("payoutGlobalStore", {
  state: () => ({
    recipientEmail: "",
    token: "",
    redemptionData: null as RedemptionData | null,
    isFulfilled: false,
    hasSubmittedRecipientDetails: false,
    showContactSupportModal: false,
  }),
  actions: {
    updateRecipientEmail(email: string) {
      this.recipientEmail = email;
    },
    updateToken(token: string) {
      this.token = token;
    },
    setRedemptionData(redemptionData: RedemptionData) {
      this.redemptionData = { ...redemptionData };
    },
    setHasSubmittedRecipientDetails(hasSubmitted: boolean) {
      this.hasSubmittedRecipientDetails = hasSubmitted;
    },
    setIsFulfilled(isFulfilled: boolean) {
      this.isFulfilled = isFulfilled;
    },
    setShowContactSupportModal(show: boolean) {
      this.showContactSupportModal = show;
    },
    async ensureRedemptionData(assetToken: string) {
      try {
        this.token = assetToken;

        if (!this.redemptionData) {
          const details = await getSpendDetails(this.token, null, false);
          this.redemptionData = details?.redemptionInformation || null;

          if (!this.redemptionData) {
            throw new Error("Failed to fetch redemption data");
          }
        }

        return this.redemptionData;
      } catch (error) {
        console.error("Error fetching redemption data:", error);
        throw error;
      }
    },
  },
});
