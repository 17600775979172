<script setup lang="ts">
import { usePayoutGlobalStore } from "../../../payout/src/global-store";
import { getCurrencySymbol } from "@two-ui/util/currency";
import { computed, ref } from "vue";
import paytocardimg from "../assets/paytocardimg.svg";
import paytocardimgFulfilled from "../assets/paytocardImgFulfilled.svg";

const props = defineProps<{
  showCardImg?: boolean;
  customHeaderTitle?: string;
}>();

const { redemptionData, isFulfilled } = usePayoutGlobalStore();

const cardImage = computed(() => {
  return isFulfilled ? paytocardimgFulfilled : paytocardimg;
});

const headerTitle = computed(() =>
  redemptionData?.fulfilmentOrderDetails
    ? "Balance transferred"
    : isFulfilled
    ? "Transfer success"
    : "Your balance"
);

const displayTitle = computed(() =>
  props.customHeaderTitle ? props.customHeaderTitle : headerTitle.value
);
</script>

<template>
  <div
    class="flex flex-col items-center gap-4"
    data-testid="pay-to-card-header"
  >
    <h1
      class="font-f37lineca mb-0 text-3xl font-[850]"
      data-testid="pay-to-card-header-title"
    >
      {{ displayTitle }}
    </h1>
    <p
      v-if="!isFulfilled && !redemptionData?.fulfilmentOrderDetails"
      class="bg-gradient-green flex items-center justify-center rounded-2xl px-6 py-4 text-3xl font-bold text-black"
      data-testid="pay-to-card-amount-box"
      role="heading"
      aria-level="2"
      aria-label="Available balance: {{ getCurrencySymbol(redemptionData?.currency) }}{{ Number(redemptionData?.value).toFixed(2) }}"
    >
      {{ getCurrencySymbol(redemptionData?.currency)
      }}{{ Number(redemptionData?.value).toFixed(2) }}
    </p>
    <img
      v-if="props.showCardImg"
      :src="cardImage"
      class="m-auto max-w-[350px] py-2"
      alt=""
      aria-hidden="true"
      data-testid="pay-to-card-image"
    />
  </div>
</template>

<style scoped lang="sass">
.bg-gradient-green
  background: linear-gradient(40.54deg, #00FFA3 -21.13%, #CCFFED 14.93%, #E9FFE6 50.24%, #FCFEDC 87.29%, #EEFC51 123.11%)
</style>
