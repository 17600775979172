<script lang="ts" setup>
import TwoMessageBox from "@two-components/components/two-messagebox/TwoMessageBox.vue";
import PayToCardHeader from "@recipient/components/PayToCardHeader.vue";

import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import { useRouter } from "vue-router";
import { ref, computed } from "vue";

const payoutGlobalStore = usePayoutGlobalStore();
const router = window.globalRouter || useRouter();

const privacyAccepted = ref(false);
const showError = ref(false);

// Add computed properties to safely access redemptionData
const fulfilmentOrderDetails = computed(
  () => payoutGlobalStore.redemptionData?.fulfilmentOrderDetails ?? null
);

const primaryColor = computed(
  () =>
    payoutGlobalStore.redemptionData?.customisation?.primaryColour ?? "black"
);

const validateUser = () => {
  if (!privacyAccepted.value) {
    showError.value = true;
    return;
  } else {
    showError.value = false;
    router.push({ name: "recipient-details" });
  }
};
</script>

<template>
  <PayToCardHeader show-card-img />
  <TwoMessageBox type="info" v-if="!fulfilmentOrderDetails">
    <p
      class="font-sans text-base font-normal"
      data-testid="pay-to-card-message-box"
    >
      Provide your details to transfer your balance directly into your account.
    </p>
  </TwoMessageBox>
  <TwoMessageBox v-else>
    <p
      class="font-sans text-base font-normal"
      data-testid="pay-to-card-message-box"
    >
      This balance has already been transferred. If you think there is a
      mistake, please contact the payment issuer.
    </p>
  </TwoMessageBox>

  <div class="w-full">
    <!-- Privacy Checkbox Container -->
    <div class="flex w-full gap-2 print:hidden" v-if="!fulfilmentOrderDetails">
      <label class="flex w-full cursor-pointer flex-col items-start gap-2">
        <div class="flex items-center gap-[12px]">
          <input
            type="checkbox"
            v-model="privacyAccepted"
            class="checkbox-custom cursor-pointer appearance-none rounded border-2"
            :class="{ 'border-red-700': showError }"
            @change="showError = false"
            data-testid="privacy-checkbox"
          />
          <span
            class="font-inter text-left text-[15px] font-normal leading-5"
            data-testid="privacy-text"
          >
            By checking this box, you confirm that you have reviewed and
            accepted our
            <a
              href="https://runa.io/privacy"
              target="_blank"
              class="text-sm font-bold text-[#505BF0] hover:underline"
              >privacy policy.</a
            >
          </span>
        </div>
        <div class="flex flex-col gap-[12px]">
          <span
            v-if="showError"
            class="font-inter text-[14px] font-normal text-red-700"
            data-testid="privacy-error"
          >
            You need to accept our Privacy Policy before continuing
          </span>
        </div>
      </label>
    </div>

    <!-- Button Container -->
    <div
      class="mt-4 flex h-[44px] w-full items-center justify-center print:hidden"
      data-testid="button-container"
    >
      <button
        class="flex h-[44px] flex-1 items-center justify-center rounded-xl text-center text-white drop-shadow-md hover:drop-shadow-lg sm:max-w-[327px]"
        :style="{
          backgroundColor: primaryColor,
          opacity: fulfilmentOrderDetails ? 0.5 : 1,
        }"
        data-testid="redeem-payout-button"
        @click="validateUser"
        :disabled="!!fulfilmentOrderDetails"
      >
        <span class="font-bold">Get started</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.checkbox-custom {
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  border-width: 2px;
}

input[type="checkbox"] {
  accent-color: v-bind("primaryColor");
}

input[type="checkbox"]:checked {
  background-color: v-bind("primaryColor");
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

input[type="checkbox"].border-red-700 {
  border: 1px solid rgb(239 68 68);
}
</style>
