<template>
  <div
    class="font-f37lineca mb-4 rounded-2xl bg-white p-4"
    v-if="props.price && props.price >= 2"
  >
    <h1 class="text-lg">Before you go</h1>
    <p class="my-2 font-sans text-sm font-normal leading-[20px]">
      Instead of a {{ currency }} {{ price }} gift card, are you interested in
      receiving {{ currency }} {{ getExperimentPrice() }} directly to your bank
      card?
    </p>
    <img
      src="https://imagedelivery.net/C0Y-4nEEMc16-K7sKznE7w/8f93b0fa-1ea5-42e3-ed5d-ebf8e8302800/public"
      alt="Pay To Card"
    />
    <div class="mt-4 w-full border-t border-[#D4D4D4] pt-4">
      <TwoButton
        class="flex w-full items-center justify-center gap-1"
        @click="showModalInterested = true"
        :data-testId="`${generateDataTestIdByPrice()}`"
      >
        <font-awesome-icon :icon="['far', 'thumbs-up']" class="text-[20px]" />
        <span class="text-sm"> I’m interested </span>
      </TwoButton>
    </div>
    <Modal
      :closable="true"
      :show="showModalInterested"
      @close="showModalInterested = false"
      title="Thank you for your interest"
      containerClass="sm:max-w-[626px] full-minus-32 mx-4"
    >
      <template #body>
        <div>
          <p class="text-base font-normal text-black">
            This payout option is not quite ready yet but we're excited to say
            it will be soon
          </p>
        </div>
      </template>
      <template #footer>
        <TwoButton
          class="btn-black"
          data-testId="close-interested"
          @click="showModalInterested = false"
        >
          Close
        </TwoButton>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import TwoButton from "@two-components/components/TwoButton.vue";
import Modal from "@two-ui/components/common/Modal.vue";
import { CurrencyEnum } from "../../api.generated/scion";
import goalMapping from "./GoalMapping";
const showModalInterested = ref(false);

const props = defineProps<{
  price: number | undefined;
  currency: CurrencyEnum | undefined;
}>();

const marginPricing = ref(0);

onMounted(() => {
  marginPricing.value = (window as any).marginPricing;
  (window as any)._conv_q = (window as any)._conv_q || [];
  (window as any)._conv_q.push([
    "triggerConversion",
    goalMapping[generateDataTestIdByPrice() as keyof typeof goalMapping],
  ]);
});

const generateDataTestIdByPrice = () => {
  if (!props.price) return "";
  if (props.price >= 200) {
    return `dynamic-pricing-price-greater-than-200-margin-${marginPricing.value}`;
  } else if (props.price >= 100) {
    return `dynamic-pricing-price-200-100-margin-${marginPricing.value}`;
  } else if (props.price >= 50) {
    return `dynamic-pricing-price-100-50-margin-${marginPricing.value}`;
  } else if (props.price >= 25) {
    return `dynamic-pricing-price-50-25-margin-${marginPricing.value}`;
  } else if (props.price >= 5) {
    return `dynamic-pricing-price-25-5-margin-${marginPricing.value}`;
  } else {
    return `dynamic-pricing-price-5-2-margin-${marginPricing.value}`;
  }
};

const getExperimentPrice = () => {
  if (!props.price) return 0;
  if (props.price >= 200) {
    return props.price - 10 + marginPricing.value;
  } else if (props.price >= 100) {
    return props.price - 5 + marginPricing.value;
  } else if (props.price >= 50) {
    return props.price - 4 + marginPricing.value;
  } else if (props.price >= 25) {
    return props.price - 2 + marginPricing.value;
  } else if (props.price >= 5) {
    return props.price - 1.5 + marginPricing.value;
  } else {
    return props.price - 1 + marginPricing.value;
  }
};
</script>
