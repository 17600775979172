/*
For our locales we are using
https://www.rfc-editor.org/info/bcp47
A valid BCP 47 tag consists of:

A language code (ISO 639-1)
A region code (ISO 3166-1 alpha-2)
For example: de-BE (German in Belgium) or bg-BG (Bulgarian in Bulgaria)
These however are a subset of all cldr locales.
https://github.com/unicode-org/cldr-json/tree/main/cldr-json/cldr-localenames-full/main
 */
const localeRegex = /^[a-z]{2}(-[A-Z]{2})?$/;
const chineseLocales = ["zh-Hans-CN", "zh-Hant-HK"];

export function toDisplayLabel(localeCode: string): string {
  if (
    !localeRegex.test(localeCode) &&
    chineseLocales.indexOf(localeCode) === -1
  ) {
    return localeCode;
  }
  const { language, region } = new Intl.Locale(localeCode);

  const languageDisplay = new Intl.DisplayNames([language], {
    type: "language",
  });
  const langName = languageDisplay.of(language) ?? language;
  const capitalized = langName.charAt(0).toUpperCase() + langName.slice(1);

  return region ? `${capitalized} (${region})` : capitalized;
}
