const goalMapping = {
  "dynamic-pricing-price-5-2-margin-0": "100491110",
  "dynamic-pricing-price-5-2-margin-0.5": "100491112",
  "dynamic-pricing-price-5-2-margin--0.5": "100491111",
  "dynamic-pricing-price-25-5-margin-0": "100491107",
  "dynamic-pricing-price-25-5-margin-0.5": "100491108",
  "dynamic-pricing-price-25-5-margin--0.5": "100491109",
  "dynamic-pricing-price-50-25-margin-0": "100491104",
  "dynamic-pricing-price-50-25-margin-0.5": "100491105",
  "dynamic-pricing-price-50-25-margin--0.5": "100491106",
  "dynamic-pricing-price-100-50-margin-0": "100491094",
  "dynamic-pricing-price-100-50-margin-0.5": "100491095",
  "dynamic-pricing-price-100-50-margin--0.5": "100491097",
  "dynamic-pricing-price-200-100-margin-0": "100491092",
  "dynamic-pricing-price-200-100-margin-0.5": "100491094",
  "dynamic-pricing-price-200-100-margin--0.5": "100491093",
  "dynamic-pricing-price-greater-than-200-margin-0": "100491089",
  "dynamic-pricing-price-greater-than-200-margin-0.5": "100491090",
  "dynamic-pricing-price-greater-than-200-margin--0.5": "100491091",
};

export default goalMapping;
