<script setup lang="ts">
import { SpendPage } from "@payout-link/pages";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { ref, onBeforeMount, onBeforeUnmount, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { event as gaEvent } from "vue-gtag";
import { RouteNames } from "../../utils/routes";

const props = defineProps<{
  token: string | undefined;
}>();

const linkDetailsStore = useLinkDetailsStore();
const router = useRouter();
const route = useRoute();
const token = ref((route?.params?.assetToken || props.token) as string);
const isFromOldRoute = computed(() => route.name != RouteNames.NewSpend);

// Computed property to determine if the customisation should be passed as null
const effectiveCustomisation = computed(() => {
  const customisation = linkDetailsStore.customisation;
  const isEmpty = Object.keys(customisation).every(
    // @ts-ignore
    (key) => !customisation[key]
  );
  return isEmpty ? null : customisation;
});

onBeforeMount(() => {
  document.getElementById("app");
});

onMounted(() => {
  gaEvent("page_view", {
    page_title: "Spend Asset",
    hostname: window.location.hostname,
  });
});

onBeforeUnmount(() => {
  document.getElementById("app");
});
</script>

<template>
  <div class="app-background">
    <div class="m-auto max-w-3xl p-4">
      <a
        v-if="isFromOldRoute"
        @click="router.back()"
        href="#"
        class="mb-6 inline-block cursor-pointer rounded-full bg-white p-4"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </a>
      <SpendPage
        :token="token"
        :customisation="effectiveCustomisation"
        :is-asset="isFromOldRoute"
      ></SpendPage>
    </div>
    <router-view></router-view>
  </div>
</template>

<style scoped>
.app-background {
  background-color: var(--bg-color-spend);
  min-height: 100vh;
}
</style>
