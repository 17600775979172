import { countries as completeCountryList, Country } from "countries-list";

export const getCountryNamesFromCodes = (countryCodes: string[]): string => {
  const mappedCountryCodes = countryCodes.map((countryCode: string) =>
    mapApiCountryCodeToCountriesList(countryCode)
  );

  return mappedCountryCodes
    .map((countryCode: string) => {
      const country =
        completeCountryList[countryCode as keyof typeof completeCountryList];
      return country?.name;
    })
    .join(", ");
};

export const getCountryFromCode = (
  countryCode: string
): Country | undefined => {
  const mappedCountryCode = mapApiCountryCodeToCountriesList(countryCode);
  const country =
    completeCountryList[mappedCountryCode as keyof typeof completeCountryList];
  return country;
};

/*
Some country codes from the APIs are returned with a different country code than the ones in the countries-list package
*/
export const mapApiCountryCodeToCountriesList = (apiCountryCode: string) => {
  const codeMapping: { [key: string]: string } = {
    JA: "JP",
  };

  return codeMapping[apiCountryCode] || apiCountryCode;
};

export const manualActiveExperiment = ({
  experimentId,
  customerId,
  productCode,
  currency,
}: {
  experimentId: string;
  customerId: string;
  productCode: string;
  currency: string | undefined;
}): boolean => {
  try {
    if (
      ((window as any).disabledCustomerIds || []).includes(customerId) ||
      ((window as any).disabledProduceCodes || []).includes(productCode) ||
      ((window as any).disabledCurrencies || []).includes(currency)
    ) {
      return false;
    }
    if (
      (window as any).allowCurrencies &&
      !(window as any).allowCurrencies.includes(currency?.toString() || "")
    ) {
      return false;
    }
    (window as any).runExperiment = 1;
    (window as any)._conv_q = (window as any)._conv_q || [];
    (window as any)._conv_q.push(["executeExperiment", experimentId]);
    return true;
  } catch {
    return false;
  }
};
