import _ from "lodash";
import { Comparable } from "@payout-link/common/models/payout-locale.model";

export class DeepSet<T extends Comparable<T>> {
  private readonly items: T[];
  // less performant but simpler than -1 | 0 | 1
  private readonly compare: (item1: T, item2: T) => boolean;

  constructor(props?: {
    items?: T[];
    compare?: (item1: T, item2: T) => boolean;
  }) {
    this.items = props?.items || [];
    this.compare = props?.compare || ((item1, item2) => item1.equals(item2));
  }

  has(item: T): boolean {
    return this.items.some((existing) => this.compare(existing, item));
  }

  add(...items: T[]): DeepSet<T> {
    items.forEach((item) => {
      if (!this.has(item)) {
        this.items.push(item);
      }
    });

    return this;
  }

  values(): T[] {
    return [...this.items];
  }

  duplicate(): DeepSet<T> {
    return DeepSet.clone(this);
  }

  static clone<T extends Comparable<T>>(deepSet: DeepSet<T>): DeepSet<T> {
    return new DeepSet({
      items: _.cloneDeep(deepSet.values()),
      compare: deepSet.compare,
    });
  }
}
