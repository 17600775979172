/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {
  ApiError,
  CancelablePromise,
  RedemptionResponse,
} from "../api.generated/scion";
import { request as __request } from "../api.generated/scion/core/request";
import { parseBooleanStrict } from "./parse-boolean-strict";

export type stageType = "PRE_FULFILLMENT" | "POST_FULFILMENT";
export type userActionType =
  | "OPEN_LINK"
  | "REVEAL_CARD_DETAILS"
  | "WAIT_FOR_NOW"
  | "IM_READY_TO_SPEND"
  | "SPEND_ONLINE"
  | "HOW_DO_I_USE_MY_GIFT_CARD"
  | "IM_INTERESTED_PAY_TO_CARD";
export class FulfilmentClient {
  /**
   * Redeem an ECode from a Runa Payout Link
   * @param eCodeTokenCode
   * @param exp
   * @param fulfil
   * @returns RedemptionResponse OK
   * @throws ApiError
   */
  public static redeemECode(
    eCodeTokenCode: string,
    exp?: string,
    fulfil?: string
  ): CancelablePromise<RedemptionResponse> {
    const SPL_FULFILMENT_PATH = "/v1/payout/asset";

    const isNewFulfilmentEndpointEnabled = parseBooleanStrict(
      import.meta.env.VITE_ENABLE_NEW_FULFILMENT_ENDPOINT
    );

    if (isNewFulfilmentEndpointEnabled && parseBooleanStrict(fulfil)) {
      return __request<RedemptionResponse>({
        method: "POST",
        path: `${SPL_FULFILMENT_PATH}/${eCodeTokenCode}/fulfil`,
        errors: {
          409: `Conflict`,
          424: `Failed Dependency`,
          429: `Too Many Requests`,
        },
      });
    }
    return __request<RedemptionResponse>({
      method: "GET",
      path: `${SPL_FULFILMENT_PATH}/${eCodeTokenCode}`,
      query: {
        exp: exp,
        fulfil: fulfil,
      },
    });
  }

  public static sentEventTracking(
    eCodeTokenCode: string,
    stage: stageType,
    userAction: userActionType,
    options?: any
  ): CancelablePromise<RedemptionResponse> {
    const SPL_FULFILMENT_PATH = "/v1/payout/asset";

    return __request({
      method: "POST",
      body: {
        stage,
        user_action: userAction,
        ...options,
      },
      path: `${SPL_FULFILMENT_PATH}/${eCodeTokenCode}/event`,
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
