<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import { captureException as logSentryException } from "@sentry/vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { initMixpanel, trackEvent } from "@two-ui/mixpanel";
import {
  ApiError,
  CustomerData,
  ProductData,
  RedemptionData,
  RedemptionErrorResponse,
  RedemptionResponse,
  ProductSpendTypeEnum,
} from "../../api.generated/scion";
import {
  Collapsible,
  ErrorMessage,
  FAQs,
  Footer,
  HowTo,
  LoadingView as SpendLoadingView,
  SavooBanner,
  SpendDetails,
  Support,
} from "../../components";
import { TIMEOUT_ERROR_CODE } from "../../constant";
import { getSpendDetails } from "../../services/redemption";
import { LoadingView } from "@workspace/packages/payout/src/components";
import Markdown from "markdown-it";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";
import {
  mergeI18nMessages,
  tryFetchTranslationMessages,
  I18nMessages,
} from "../../i18n-messages/i18n-messages";
import * as Sentry from "@sentry/vue";
import { OrderItemCustomisation } from "@payout-link/api.generated/scion";
import TwoSelectField from "@two-components/components/TwoSelectField.vue";
import PayToCardDetails from "@payout-link/components/PayToCard/PayToCardDetails.vue";
import { usePayoutGlobalStore } from "@workspace/packages/payout/src/global-store";
import DynamicPricing from "../../components/Experiment/DynamicPricing.vue";
import { manualActiveExperiment } from "@workspace/utils/utils";
import { ECode } from "@payout-link/api.generated/scion";
import PayToCardSupport from "@payout-link/components/PayToCard/PayToCardSupport.vue";
import { localeToIetfStandard } from "@workspace/packages/payout/src/utils/format";
import { escapeI18nSpecialCharacters } from "@payout-link/util/i18n/escape-i18n-special-characters";
import {
  PayoutLocale,
  PayoutLocaleImpl,
} from "@payout-link/common/models/payout-locale.model";
import { searchLocale } from "@payout-link/util/i18n/locale/search-locale";
import { DeepSet } from "@payout-link/common/collection/set/deep-set";
import { FAQ, FAQTranslationKeys } from "@payout-link/common/types/faq";

const ENABLE_SAVOO_EXPERIMENT = import.meta.env.VITE_ENABLE_SAVOO_EXPERIMENT;
const SAVOO_EXPERIMENT_ENABLED_PRODUCTS =
  import.meta.env.VITE_SAVOO_EXPERIMENT_ENABLED_PRODUCTS?.split(",").map(
    (product: string) => product.trim()
  );
const ENABLE_LOCALE_SELECT =
  import.meta.env.VITE_ENABLE_LOCALE_SELECT === "true";

const FALLBACK_PAYOUT_LOCALE: PayoutLocale = new PayoutLocaleImpl("en-US");

const props = defineProps<{
  token: string;
  // The customisation parameter is coming from the Wallet app
  customisation: OrderItemCustomisation | null;
  isAsset?: boolean;
}>();

const route = useRoute();
const { locale, setLocaleMessage, t: $t } = useI18n();
const preFulfilmentDetails = ref<RedemptionResponse>();
const fulfilmentDetails = ref<RedemptionResponse>();
const isPaymentProduct = computed(
  () => preFulfilmentDetails.value?.redemptionInformation?.isPaymentProduct
);
const payoutGlobalStore = usePayoutGlobalStore();
const redemptionErrorCode = ref<string>();
const isLoading = ref<boolean>(true);
const hasFailedLoadingRedemptionDetails = ref<boolean>(false);
const isExternalURL = ref<boolean>(true);
const areTranslationsLoaded = ref<boolean>(false);
const blurryCodesLoading = ref<boolean>(false);
const isLongerThan30s = ref<boolean>(false);
const canadianLocaleOptions = [
  { id: "0", value: "en-CA", label: "English (CA)" },
  { id: "1", value: "fr-CA", label: "Français (CA)" },
];
const additionalSelectLocales = ref<DeepSet<PayoutLocale>>(
  new DeepSet<PayoutLocale>()
);

const isLocaleSelectEnabled = computed((): boolean => {
  return (
    ENABLE_LOCALE_SELECT &&
    payoutCountryLocales.value.length > 0 &&
    !productIsCanadian.value &&
    !props.isAsset
  );
});

// The supportedLocales collection is populated by the keys found in the fetched translation messages
const supportedLocales = ref<DeepSet<PayoutLocale>>(
  new DeepSet<PayoutLocale>()
);

// The payoutCountryLocales are the supported locales that match the country of the product
const payoutCountryLocales = computed((): PayoutLocale[] => {
  return supportedLocales.value
    .values()
    .filter((payoutLocale) => payoutLocale.value.includes("-"))
    .filter(
      (payoutLocale) =>
        payoutLocale.value.split("-")[1] === product.value?.countryCode
    );
});

const payoutCountryLocaleOptions = computed((): PayoutLocale[] => {
  return payoutCountryLocales.value.concat(
    additionalSelectLocales.value.values()
  );
});

const clickedShowCodes = ref<boolean>(false);
const experimentDynamicPricing = ref(false);
const mainContentRef = ref<HTMLElement | null>(null);

const internalUrlParameters = ["exp", "locale"];

function deleteIternalUrlParam(params: URLSearchParams): URLSearchParams {
  internalUrlParameters.forEach((internalParameterName) =>
    params.delete(internalParameterName)
  );
  return params;
}

function createExternalRedemptionLink(externalUrl: string): string {
  const parsedUrl = new URL(externalUrl);
  const externalParameters = deleteIternalUrlParam(
    new URLSearchParams(window.location.search)
  );
  externalParameters.forEach((value, key, _) =>
    parsedUrl.searchParams.append(key, value)
  );

  return parsedUrl.href;
}

const handleShowCodes = async () => {
  blurryCodesLoading.value = true;
  try {
    const data = await getSpendDetails(props.token, exp.value, true);

    const externalUrl = data?.externalRedemptionUrl;
    if (!!externalUrl) {
      window.location.href = createExternalRedemptionLink(externalUrl);
    } else {
      fulfilmentDetails.value = data;
    }

    clickedShowCodes.value = true;
  } catch (e) {
    setRedemptionError(e as ApiError);
    hasFailedLoadingRedemptionDetails.value = true;
    console.error(e);
  } finally {
    blurryCodesLoading.value = false;
  }
};

const updateLongerThan30s = () => {
  isLongerThan30s.value = true;
  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
};

const setRedemptionError = (e: ApiError) => {
  let errorResponse: RedemptionErrorResponse;
  if (e?.body) {
    try {
      errorResponse = e.body;
      redemptionErrorCode.value = errorResponse.code || errorResponse.errorCode;
    } catch (parseError) {
      console.error("Failed to parse error response", parseError);
      redemptionErrorCode.value = "RE999";
    }
  }
};

const findPreferredLocale = (
  queryLocaleIetfStandard: string | null,
  productLocaleIetfStandard?: string | null
): string | undefined => {
  const queryLocaleMatch = queryLocaleIetfStandard
    ? searchLocale(
        [payoutCountryLocales.value, supportedLocales.value.values()],
        queryLocaleIetfStandard
      )
    : undefined;
  const productLocaleMatch = productLocaleIetfStandard
    ? searchLocale(
        [payoutCountryLocales.value, supportedLocales.value.values()],
        productLocaleIetfStandard
      )
    : undefined;
  return queryLocaleMatch ?? productLocaleMatch;
};

const redemptionInformation = computed(() => {
  if (
    preFulfilmentDetails.value &&
    preFulfilmentDetails.value.redemptionInformation
  ) {
    if (props.customisation) {
      // @ts-ignore
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      preFulfilmentDetails.value.redemptionInformation.customisation =
        props.customisation;
    }
    const newBackgroundColor =
      preFulfilmentDetails.value?.redemptionInformation?.customisation
        ?.backgroundColour;
    if (newBackgroundColor) {
      document.documentElement.style.setProperty(
        "--bg-color-spend",
        newBackgroundColor
      );
    }
  }
  return preFulfilmentDetails.value?.redemptionInformation as RedemptionData;
});
const eCode = computed((): ECode => fulfilmentDetails.value?.eCode as ECode);
const customer = computed(
  () =>
    preFulfilmentDetails.value?.redemptionInformation?.customer as CustomerData
);
const product = computed(
  () =>
    preFulfilmentDetails.value?.redemptionInformation?.product as ProductData
);
/*
Vue I18n provides an implicit fallback mechanism for missing translations which is based on the locale hierarchy.
To ensure the fallback works correctly, locale codes must follow the IETF BCP 47 standard.
For more details, refer to: https://vue-i18n.intlify.dev/guide/essentials/fallback.html#implicit-fallback-using-locales
*/
const productLocaleIetfStandard = computed(() => {
  return product.value?.fullLanguageCode
    ? localeToIetfStandard(product.value.fullLanguageCode)
    : null;
});

const queryLocaleIetfStandard = computed(() => {
  const queryLocale = route.query.locale; // this returns null, string or string[]

  const locale = Array.isArray(queryLocale) ? queryLocale[0] : queryLocale;

  return locale ? localeToIetfStandard(locale) : null;
});

const support = computed(
  () => preFulfilmentDetails.value?.redemptionInformation?.support
);
const supportUrl = computed(() => support.value?.url);
const hasSupportInfo = computed(
  () => support.value && (support.value.phone || support.value.url)
);
const exp = computed(() => (route?.query?.exp as string) || null);
const savooEnabledAndLinkHasBanner = computed(() => {
  return (
    ENABLE_SAVOO_EXPERIMENT === "true" &&
    SAVOO_EXPERIMENT_ENABLED_PRODUCTS.includes(product.value.code)
  );
});
const productIsCanadian = computed(() => {
  return product.value?.countryCode === "CA";
});

function setLocale(supportedPayoutLocale: string) {
  additionalSelectLocales.value = new DeepSet<PayoutLocale>();
  if (!searchLocale([payoutCountryLocales.value], supportedPayoutLocale)) {
    additionalSelectLocales.value = additionalSelectLocales.value
      .add(new PayoutLocaleImpl(supportedPayoutLocale))
      .duplicate();
  }
  locale.value = supportedPayoutLocale;
}

function setPreferredOrFallbackLocale(
  queryLocaleIetfStandard: string | null,
  productLocaleIetfStandard: string | null
) {
  const preferredLocale = queryLocaleIetfStandard || productLocaleIetfStandard;
  if (payoutCountryLocales.value.length > 0) {
    // as per locale hierarchy defined here:
    // https://www.notion.so/runahq/Payout-App-Default-Locale-1b4c1d69b5af80ed97d5ce04e09006cd
    const supportedPayoutLocale = findPreferredLocale(
      queryLocaleIetfStandard,
      productLocaleIetfStandard
    );
    if (!supportedPayoutLocale) {
      setLocale(FALLBACK_PAYOUT_LOCALE.value);
    } else {
      setLocale(supportedPayoutLocale);
    }
    if (
      preferredLocale &&
      payoutCountryLocales.value.length > 0 &&
      !supportedPayoutLocale
    ) {
      const errorMessage = `The locale ${preferredLocale} is not supported for the product country. \
          Defaulting to the first supported locale.`;

      console.warn(errorMessage);
      logSentryException(new Error(errorMessage));
    }
  } else {
    const initialLocale = preferredLocale
      ? searchLocale([supportedLocales.value.values()], preferredLocale)
      : undefined;
    setLocale(initialLocale || FALLBACK_PAYOUT_LOCALE.value);
  }
}

/**
 * Before the pre-fulfilment details are loaded the locale is set based on the following priority:
 * 1. queryParams.locale
 * 2. en-US
 *
 * After the base translations are loaded and the payoutCountryLocales are updated, the above hierarchy is used
 * for the initial locale value. We then search for the locale in the payoutCountryLocales and use it if it is found.
 *
 * If the locale is not found in the payoutCountryLocales, we default to the first supported locale
 * and log a warning to the console and an error to sentry.
 * 1. queryParams.locale
 * 2. product.locale
 * 3. en-US
 *
 * Decided in: https://www.notion.so/runahq/Payout-App-Default-Locale-1b4c1d69b5af80ed97d5ce04e09006cd
 */
watch([productLocaleIetfStandard, payoutCountryLocales], () => {
  setPreferredOrFallbackLocale(
    queryLocaleIetfStandard.value,
    productLocaleIetfStandard.value
  );
});

const displayBlurryCodes = computed(() => {
  // backwards compatible with backend not returning isBlurryCodesFulfilment parameter
  // once both FE and BE are released, this function can be simplified
  if (redemptionInformation.value?.isBlurryCodesFulfilment !== undefined) {
    if (!eCode?.value) {
      return redemptionInformation.value.isBlurryCodesFulfilment;
    }
  }
  return !eCode?.value;
});

const HIDE_RUNA_MESSAGE_CUSTOMER_IDS = import.meta.env
  .VITE_HIDE_RUNA_MESSAGE_CUSTOMER_IDS
  ? JSON.parse(import.meta.env.VITE_HIDE_RUNA_MESSAGE_CUSTOMER_IDS)
  : [];

const shouldHideRunaMessage = computed(() =>
  HIDE_RUNA_MESSAGE_CUSTOMER_IDS.includes(customer.value?.hashedId)
);

const HIDE_CONTACT_SENDER_MESSAGE_CUSTOMER_IDS = import.meta.env
  .VITE_HIDE_CONTACT_SENDER_MESSAGE_CUSTOMER_IDS
  ? JSON.parse(import.meta.env.VITE_HIDE_CONTACT_SENDER_MESSAGE_CUSTOMER_IDS)
  : [];

const shouldHideContactSenderMessage = computed(() =>
  HIDE_CONTACT_SENDER_MESSAGE_CUSTOMER_IDS.includes(customer.value?.hashedId)
);

const VITE_HIDE_PAY_TO_CARD_CUSTOMER_IDS = import.meta.env
  .VITE_HIDE_PAY_TO_CARD_CUSTOMER_IDS
  ? JSON.parse(import.meta.env.VITE_HIDE_PAY_TO_CARD_CUSTOMER_IDS)
  : [];

const shouldShowHowTo = computed(() => {
  return (
    product?.value?.redeemInstructions &&
    !(isPaymentProduct.value && payoutGlobalStore.isFulfilled)
  );
});

const translatedRedeemInstructions = computed(() => {
  const productRedeemInstructions =
    escapeI18nSpecialCharacters(product.value?.redeemInstructions) ?? "";

  return $t("message.redeemInstructions", productRedeemInstructions || "");
});

const translatedSenderName = computed(() =>
  $t(
    "message.senderName",
    redemptionInformation.value?.customisation?.senderName ||
      customer.value?.name ||
      ""
  )
);

const translatedCustomLogoAlt = computed(() =>
  $t("message.custom_logo_alt", "Custom logo image")
);

const translatedTerms = computed(() => {
  const productTerms = escapeI18nSpecialCharacters(product.value?.terms) ?? "";
  return $t("message.terms", productTerms);
});

const getFAQTranslationKeys = (key: string): FAQTranslationKeys => ({
  spendingType: `message.faqs.${key}.spendingType`,
  question: `message.faqs.${key}.question`,
  rawAnswer: `message.faqs.${key}.rawAnswer`,
  isBooleanAnswer: `message.faqs.${key}.isBooleanAnswer`,
  booleanAnswer: `message.faqs.${key}.booleanAnswer`,
});

const getTranslatedFAQ = (keys: FAQTranslationKeys): FAQ | null => {
  const spendingType = $t(
    keys.spendingType,
    keys.spendingType
  ) as ProductSpendTypeEnum;
  const question = $t(keys.question, keys.question);
  const rawAnswer = $t(keys.rawAnswer, keys.rawAnswer);
  const isBooleanAnswer = $t(keys.isBooleanAnswer, "false") === "true";
  const booleanAnswer = $t(keys.booleanAnswer, "false") === "true";

  // If any of the required fields are missing or unchanged (meaning no translation found)
  if (
    !spendingType ||
    !question ||
    !rawAnswer ||
    spendingType === keys.spendingType ||
    question === keys.question ||
    rawAnswer === keys.rawAnswer
  ) {
    return null;
  }

  return {
    spendingType,
    question,
    rawAnswer,
    isBooleanAnswer,
    booleanAnswer,
  };
};

const getProductFAQs = (): FAQ[] =>
  product.value?.faqs?.map((faq) => ({
    spendingType: faq.spendingType as ProductSpendTypeEnum,
    question: faq.question || "",
    rawAnswer: faq.rawAnswer || "",
    isBooleanAnswer: faq.isBooleanAnswer || false,
    booleanAnswer: faq.booleanAnswer || false,
  })) || [];

const translatedFaqs = computed(() => {
  const MAX_FAQS = 10;
  const translatedFaqsArray: FAQ[] = [];

  // Try to get translated FAQs from s3 first
  for (let i = 1; i <= MAX_FAQS; i++) {
    const key = `faq${i}`;
    const translationKeys = getFAQTranslationKeys(key);
    const translatedFAQ = getTranslatedFAQ(translationKeys);

    if (!translatedFAQ) {
      // If we couldn't find any translations at all, fall back to product FAQs
      if (i === 1) {
        return getProductFAQs();
      }
      // If we found atleast 1 translation but then hit a missing one, return those
      break;
    }

    translatedFaqsArray.push(translatedFAQ);
  }

  return translatedFaqsArray;
});

function updateLocaleMessages(messages: I18nMessages) {
  Object.keys(messages).forEach((payoutLocale) => {
    setLocaleMessage(payoutLocale, messages[payoutLocale]);

    supportedLocales.value.add(new PayoutLocaleImpl(payoutLocale));
  });
  //  Vue does not track mutations inside objects,
  //  therefore triggering ref reactivity requires updating object reference
  supportedLocales.value = supportedLocales.value.duplicate();
}

onMounted(async () => {
  let baseTranslations;
  try {
    baseTranslations = await tryFetchTranslationMessages();
    const messages = baseTranslations || ({} as I18nMessages);
    updateLocaleMessages(messages);
    areTranslationsLoaded.value = true;
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    isLoading.value = false;
    console.error(e);
    Sentry.captureException(
      new Error(`Failed to fetch base translation messages: "${e}"`)
    );
    return;
  }

  try {
    preFulfilmentDetails.value = await getSpendDetails(
      props.token,
      exp.value,
      false
    );

    if (preFulfilmentDetails.value?.redemptionInformation) {
      payoutGlobalStore.setRedemptionData(
        preFulfilmentDetails.value?.redemptionInformation
      );
    }

    if (props.token) {
      payoutGlobalStore.updateToken(props.token);
    }

    // We need to auto fulfil if the fulfilment is a non-blurry codes one and the product is not pay to card
    if (!displayBlurryCodes.value && !isPaymentProduct.value) {
      try {
        fulfilmentDetails.value = await getSpendDetails(
          props.token,
          exp.value,
          true
        );
      } catch (e) {
        setRedemptionError(e as ApiError);
        hasFailedLoadingRedemptionDetails.value = true;
      }
    }

    if (preFulfilmentDetails?.value?.status !== "SUCCESS") {
      hasFailedLoadingRedemptionDetails.value = true;
      redemptionErrorCode.value = fulfilmentDetails.value?.errorCode;
    }

    const externalUrl = fulfilmentDetails.value?.externalRedemptionUrl;

    if (!!externalUrl) {
      isExternalURL.value = true;
      window.location.href = createExternalRedemptionLink(externalUrl);
      return;
    } else {
      isExternalURL.value = false;
      const eventData = {
        customerId: customer.value?.hashedId,
        savooAciveAndLinkHasBanner: savooEnabledAndLinkHasBanner.value,
        blurryCodesEnabled: displayBlurryCodes.value,
        fixedPricing: false,
        dynamicPricing: false,
        productCode: product.value?.code,
        price: redemptionInformation.value.value,
        currency: redemptionInformation.value.currency,
      };
      if (
        displayBlurryCodes.value &&
        manualActiveExperiment({
          experimentId: import.meta.env
            .VITE_CONVERT_EXPERIMENT_ID_P2C_DYNAMIC_PRCING,
          customerId: customer.value?.hashedId,
          productCode: product.value?.code,
          currency: redemptionInformation.value.currency,
        })
      ) {
        experimentDynamicPricing.value = eventData.dynamicPricing = (
          window as any
        ).dynamicPricing;
      }
      trackEvent(
        'Network: User navigates to the "Payout Link" page.',
        eventData,
        props.token
      );
    }
  } catch (e) {
    setRedemptionError(e as ApiError);
    hasFailedLoadingRedemptionDetails.value = true;
  }

  try {
    const productTranslations = await tryFetchTranslationMessages(
      product.value?.code
    );
    const translations = mergeI18nMessages(
      baseTranslations,
      productTranslations
    );
    const messages = translations || ({} as I18nMessages);
    areTranslationsLoaded.value = true;
    updateLocaleMessages(messages);
  } catch (e) {
    hasFailedLoadingRedemptionDetails.value = true;
    isLoading.value = false;
    console.error(e);
    return;
  }

  isLoading.value = false;
});

const onTnCMounted = () => {
  if (translatedTerms.value) {
    const md = new Markdown();
    const howTo = md.render(translatedTerms.value);
    const contentContainer = document.getElementById("tncs");

    if (contentContainer) {
      contentContainer.innerHTML = howTo;
    }
  }
};

initMixpanel();
</script>

<template>
  <LoadingView
    v-if="isLoading"
    :translations-loaded="areTranslationsLoaded"
    :customer-support-url="supportUrl"
  />

  <div
    v-else-if="hasFailedLoadingRedemptionDetails || isLongerThan30s"
    data-testid="error-message-container"
  >
    <ErrorMessage
      :error-code="
        isLongerThan30s
          ? TIMEOUT_ERROR_CODE
          : preFulfilmentDetails?.errorCode
          ? preFulfilmentDetails?.errorCode
          : redemptionErrorCode
      "
      :is-asset="props.isAsset"
      :customer-support-url="supportUrl"
    />
  </div>

  <div v-else-if="!isExternalURL" class="flex w-full justify-center">
    <main
      ref="mainContentRef"
      class="max-w-[528px]"
      aria-label="Gift card details"
    >
      <DynamicPricing
        v-if="experimentDynamicPricing"
        :price="redemptionInformation.value"
        :currency="redemptionInformation.currency"
      />
      <section v-if="productIsCanadian" class="flex justify-end">
        <TwoSelectField
          v-model="locale"
          :options="canadianLocaleOptions"
          custom-select-class="bg-white"
          class="mb-2 w-1/4"
          external-id="canadian-locale-select"
        />
      </section>
      <section
        v-if="isLocaleSelectEnabled"
        class="flex justify-end"
        data-testid="locale-select"
      >
        <TwoSelectField
          v-model="locale"
          :options="payoutCountryLocaleOptions"
          custom-select-class="bg-white"
          class="mb-2 w-44"
          external-id="locale-select"
          :left-icon="['far', 'globe']"
        />
      </section>

      <section v-if="savooEnabledAndLinkHasBanner" title="Savoo banner">
        <SavooBanner
          class="mb-6"
          :product-code="product.code"
          :customer-id="customer?.hashedId"
          :token="token"
        />
      </section>
      <section
        data-testid="redemption-information"
        title="Redemption information"
        class="font-f37lineca shadow-section mb-4 rounded-2xl bg-white p-4"
      >
        <div
          v-if="blurryCodesLoading"
          class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75"
        >
          <div
            class="shadow-section flex w-96 flex-col items-center justify-center rounded-lg bg-white p-6"
          >
            <SpendLoadingView
              :translations-loaded="areTranslationsLoaded"
              @longer-than-30s="updateLongerThan30s"
            />
          </div>
        </div>
        <img
          v-if="redemptionInformation?.customisation?.logoUrl"
          class="m-auto rounded-2xl p-4"
          :src="redemptionInformation?.customisation?.logoUrl"
          :alt="translatedCustomLogoAlt"
          data-testid="custom-logo-image"
        />
        <PayToCardDetails
          v-if="isPaymentProduct"
          :redemption-data="redemptionInformation"
        />
        <SpendDetails
          v-else
          ref="mainContentRef"
          tabindex="-1"
          :redemption-data="redemptionInformation"
          :token="token"
          :display-blurry-codes="displayBlurryCodes"
          :locale="locale"
          :e-code="eCode"
          :barcode-graphic="fulfilmentDetails?.barcodeGraphic"
          @blurry-codes-toggle="handleShowCodes"
        >
        </SpendDetails>
      </section>

      <section
        v-if="shouldShowHowTo"
        id="how-to"
        :title="$t('message.how_to_use_your_gift_card')"
        class="font-f37lineca shadow-section mb-4 rounded-2xl bg-white p-4"
      >
        <HowTo :redeem-instructions="translatedRedeemInstructions"></HowTo>
      </section>
      <PayToCardSupport
        v-if="payoutGlobalStore.isFulfilled"
        :primary-colour="
          redemptionInformation?.customisation?.primaryColour || 'black'
        "
      />
      <section
        :title="$t('message.faq_and_support')"
        :class="[
          'font-f37lineca mb-4 rounded-2xl bg-white',
          !product?.faqs?.length && !hasSupportInfo && 'hidden',
        ]"
      >
        <FAQs
          v-if="
            product?.faqs?.length &&
            !(isPaymentProduct && payoutGlobalStore.isFulfilled)
          "
          :show-tabs="!isPaymentProduct"
          class="border-b border-gray-200 last:border-b-0"
          :faqs="translatedFaqs"
          :primary-colour="
            redemptionInformation?.customisation?.primaryColour || 'black'
          "
          :product-is-canadian="productIsCanadian"
        >
        </FAQs>

        <Support
          v-if="support && hasSupportInfo"
          :product="product"
          :support="support"
          :primary-colour="
            redemptionInformation?.customisation?.primaryColour || 'black'
          "
          :is-payment-product="isPaymentProduct"
        ></Support>
      </section>

      <section
        v-if="product?.terms && !payoutGlobalStore.isFulfilled"
        :title="$t('message.terms_and_conditions')"
        class="font-f37lineca shadow-section mb-4 rounded-2xl bg-white p-4"
      >
        <Collapsible
          :title="$t('message.terms_and_conditions')"
          @vue:mounted="onTnCMounted"
        >
          <div id="tncs"></div>
        </Collapsible>
      </section>

      <!--      <section-->
      <!--        class="font-500 mb-4 flex flex-col items-center justify-center text-center text-sm leading-6 text-[#333333]"-->
      <!--      >-->
      <!--        <p>If you have any issues, please contact Runa support and quote</p>-->
      <!--        <p>-->
      <!--          E-code ID: <span class="font-bold">{{ props.token }}</span>-->
      <!--        </p>-->
      <!--      </section>-->

      <Footer
        v-if="!displayBlurryCodes"
        :e-code-data="eCode"
        :sender-name="translatedSenderName"
        :product="product"
        :hide-runa-message="shouldHideRunaMessage"
        :hide-contact-sender-message="shouldHideContactSenderMessage"
      />
    </main>
  </div>
</template>

<style scoped></style>
