<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ECodeData, ProductData } from "../../api.generated/scion";
import { ECode } from "../../api.generated/scion/fulfilment-service/models/ECode";

const { t } = useI18n();

const props = defineProps<{
  eCodeData: ECodeData | ECode | undefined;
  senderName: string;
  product: ProductData;
  hideRunaMessage: boolean;
  hideContactSenderMessage: boolean;
}>();

const displayDpSerialNumber = computed(() => {
  return props.product?.code === "JEAT-GB";
});

const eCodeId = computed(() => {
  if (props.eCodeData && "id" in props.eCodeData) {
    return props.eCodeData.id;
  }
  return props.eCodeData?.hashedId;
});

let productCodeToCompany: { [key: string]: string } = {
  "JEAT-GB": "Just Eat",
};
</script>

<template>
  <footer>
    <p class="py-4 text-center font-normal" data-testid="footer-paragraph">
      <span
        v-if="!hideRunaMessage"
        v-html="
          $t('message.egift_card_partners_runa', {
            msg: `<span data-testid=\'receiving-message-sender-name\'>${props.senderName}<\/span>`,
          })
        "
      >
      </span>
      <br v-if="!hideRunaMessage" />
      <br v-if="!hideRunaMessage" />
      <span v-if="displayDpSerialNumber">
        <p
          v-html="
            $t('message.footer_with_dp_serial_number', {
              company: productCodeToCompany[props.product.code],
              code: `<strong>${props.eCodeData?.dpSerialNumber}</strong>`,
            })
          "
        ></p>
        <br />
      </span>
      <span v-if="!hideContactSenderMessage">
        <p
          v-html="
            $t('message.contact_message_support_sender', {
              senderName: `<span data-testid=\'issues-message-sender-name\'>${props.senderName}<\/span>`,
              code: `<span data-testid=\'issues-message-code\'>${eCodeId}<\/span>`,
            })
          "
        ></p>
      </span>
    </p>
  </footer>
</template>
